<style scoped src="./index.less" lang="less" rel="stylesheet/less"></style>
<template src="./index.html"></template>
<script>
import mixinPage from "../../libs/mixinPage";
export default {
  mixins: [mixinPage],
  components: {},
  name: "help",
  data() {
    return {
      pageName:this.$options.name,
      keywords:"",
      list:[],
    };
  },
  methods: {
    getData(){
      let that = this;
      let data = {
        method: "post",
        isToken: false,
        params:{
          keywords:that.keywords
        },
        url: "ext/extvideo/list",
        successCallback(res) {
          that.list = res.data.data.list;
        }
      };

      that.$getNetWork._axios(data);
    }
  },
  mounted() {
    this.getData();
  },
  activated() {
  }
};
</script>



